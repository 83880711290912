.customcontainer {
    background-color: #fff;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.headerRow {
    display: flex;
    flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    background-color: #3b3483;
}

.headerItem {
    flex: 1;
    align-items: center;
}

.headerText {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.calendarGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.calendarItem {
    flex-basis: 14.28%;
    justify-content: center;
    align-items: center;
    padding: 2.5%;
    border-width: 0.5px;
    border-color: gray;
}

.emptyItem {
    border-width: 0;
    /* Hide border for empty items */
}

.dateText {
    font-size: 30px;
    font-weight: bold;
    color: #000;
}

.dayNameText {
    font-size: 15px;
    color: #000;
}

.todayNameText {
    font-size: 15px;
    color: #000;
}

.prakshNamePOS {
    position: absolute;
    right: -10%;
    top: -30%;
    font-size: 15px;
    color: "#000";
}

.prakshNamePOSToday {
    position: absolute;
    color: #000;
    right: -10%;
    top: -30%;
    font-size: 15px;
    color: "#000";
}

.month_year {
    font-size: 25px;
    color: #fff;
}

.item {
    background-color: #f9c2ff;
    padding: 20px;
    margin-right: 8px;
    margin-left: 8px;
}

.header {
    font-size: 32px;
    background-color: #fff;
}

.title {
    font-size: 24px;
}

.todayItem {
    /* background-color: #3b3483; */
    border: solid;
    border-color: red;
}

.AthamItem {
    background-color: #d7e7b6;
}

.PanchamItem {
    background-color: #ffc09f;
}

.ChaudasItem {
    background-color: #edd9c1;
}

.PoonamItem {
    background-color: #f4eeb2;
}

.GyarasItem {
    background-color: #c5d4e7;
}

.BijItem {
    background-color: #d5dedb;
}

.todayText {
    color: #000;
    font-weight: bold;
}

.PanchamItemText {
    font-size: 15px;
    color: #006400
}

.todayTextPacham {
    color: #006400;
    font-weight: bold;
}

.pakshaNameText {
    font-size: 15px;
    color: #006400;
}

@media (max-width:991px) {
    .dateText {
        font-size: 15px;
        padding-top: 3px;
    }

    .dayNameText {
        font-size: 8px;
        white-space: nowrap;
        overflow: hidden;
    }

    .prakshNamePOS {
        position: absolute;
        right: -15%;
        top: -12%;
        font-size: 8px;
        color: "#000";
    }

    .prakshNamePOSToday {
        color: #000;
        position: absolute;
        right: -15%;
        top: -12%;
        font-size: 8px;
        color: "#000";
    }

    .month_year {
        font-size: 15px;
        color: #fff;
    }

    .todayNameText {
        font-size: 8px;
        color: #000;
    }
}