.authentication-page-content {
  /* height: 100vh; */
  display: flex;
}

.auth-form-group-custom {
  position: relative;
}

.auth-form-group-custom .form-control {
  height: 60px;
  padding-top: 28px;
  padding-left: 60px;
}

.auth-form-group-custom label {
  position: absolute;
  top: 7px;
  left: 60px;
}

.auth-form-group-custom .auti-custom-input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 19px;
  font-size: 24px;
  color: #3b3483;
}

.dropify-wrapper .dropify-message span.file-icon {
  font-size: 22px !important;
  color: #CCC;
}

.customborder {
  border: 1px solid #ced4da;
  border-radius: 5px;
}