.progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 600px;
}

.progress {
    position: relative;
    text-align: center;
    height: 200px;
    /* background-color: #fff !important; */
    background-color : transparent !important;
}

.barOverflow {
    position: relative;
    overflow: hidden;
    width: 270px;
    height: 135px;
    margin-bottom: -28px;
}

.main-text {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 270px;
    bottom: 20px;
    font-weight: 600;
    font-size: 18px;
        color: #000 !important;
}

.bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 270px;
    height: 270px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 5px solid #eee;
    /* Updated border thickness */
    border-bottom-color: #0bf;
    border-right-color: #0bf;
}

.pie-chart-container {
    position: fixed;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    position: absolute;
}
.pie-chart-container-2 {
    position: fixed;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    position: absolute;
    margin-top: 18px;
}

.recharts-wrapper {
    top: -22px !important;
    width: 580px !important;
    height: 300px !important;
}

.sun-icon {
  position: absolute;
  bottom: 10px; /* Adjust position */
  right: 10px; /* Adjust position */
}

.marker {
  position: absolute;
  top: -8px; /* Adjust to place the marker on the circle's path */
  /* left: 50%;  */
  /* Center the marker horizontally */
  transform: translateX(-50%); /* Adjust to center */
  width: 16px; /* Size of the marker */
  height: 16px; /* Size of the marker */
  background-color: red; /* Color of the marker */
  border-radius: 50%;
  z-index: 100;
}



/* path:nth-child(1) {
  fill: none;
  stroke: #cecece;
  stroke-width: 13px;
}

path:nth-child(2) {
  fill: none;
  stroke: #1980d0;
  stroke-width: 13px;
} */

.sun circle {
  fill: #FDB813;
  stroke-width: 5px;
  r: 8.5px;
} 
.chndra circle {
  fill: #F6F1D5;
  stroke-width: 5px;
  r: 8.5px;
} 
