.input-group>.form-control,
.input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    background-color: #fff;
    color: #3b3483;
    border-radius: 15px;
    border: thick
}

.arrowleft {
    text-align-last: right;
}

.form-control.w-130px.flatpickr-input.flatpickr-mobile {
    width: 130px;
}

@media (min-width:300px) {
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
    }

}

.accordion-item .isvaluetrue .accordion-button {
    /* border-radius: 10px !important; */
    background-color: #3b3483 !important;
    color: #fff;
    font-size: 18px;
    font-weight: 800;
    border-color: #3b3483;
}

.accordion-item .isvaluefalse .accordion-button {
    /* border-radius: 10px !important; */
    background-color: #3b3483bb !important;
    color: #fff;
    font-size: 18px;
    font-weight: 800;
    border-color: #3b3483;
}